<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card outlined class="mb-6">
            <v-card-title
              class="d-flex justify-start align-center grey lighten-3"
            >
              <div class="flex-grow-1">Weight</div>
            </v-card-title>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Agreement Date</th>
                  <td>
                    <div v-if="dog.fch_agreement_date">
                      {{ formatDate(dog.fch_agreement_date) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>

            <div class="vh-card">
              <div class="vh-card-body">
                <h5 class="vh-card-title">Gavin Taylor</h5>
                <p class="vh-card-text">109 Inchview North</p>
                <p class="vh-card-text">Prestonpans</p>
                <p class="vh-card-text">EH32 9SE</p>
                <p class="vh-card-text">
                  <a href="mailto:g.t.roofingcontractor@gmail.com"
                    >g.t.roofingcontractor@gmail.com</a
                  >
                </p>
                <p class="vh-card-text"><b>Mobile:</b> 07769901461</p>
                <div>
                  <a
                    href="https://www.lomondhillslabradoodles.co.uk/wp-admin/post.php?post=78394&amp;action=edit"
                    class="vh-btn vh-btn-primary"
                    target="_blank"
                    >Go To Contact</a
                  >
                  <a
                    href="#editDogModal"
                    id="changeContact"
                    class="vh-btn vh-btn-default"
                    data-toggle="modal"
                    >Select Contact</a
                  >
                </div>
                <div style="margin-top: 10px">
                  <a href="#" id="removeFCHContact">Remove Contact</a>
                </div>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  computed: {
    dog() {
      return this.$store.getters["lhl/dogs/get"];
    },
  },
};
</script>
